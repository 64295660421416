
import * as React from 'react';
import {Route, BrowserRouter} from 'react-router-dom';
import ReactDOM from 'react-dom';
import {createBrowserHistory} from "history";
import {Component, useState} from "react";
import {CarouselItem, Carousel, Card, Modal, Tab, Row, Col, ListGroup, Sonnet} from "react-bootstrap";
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
//import TimeRangePicker from '@wojtekmaj/react-timerange-picker/dist/entry.nostyle';
import { slide as Menu } from 'react-burger-menu';
import {projects, cv, Services} from "./services";
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/theme/dracula.css';
import DropdownButton from "react-bootstrap/DropdownButton";
import DropdownItem from "react-bootstrap/DropdownItem";
import {Controlled as CodeMirror} from 'react-codemirror2';
import {Sunburst, Hint, XAxis, XYPlot, LineMarkSeries, LineMarkSeriesCanvas, ChartLabel, YAxis, VerticalGridLines, HorizontalGridLines} from 'react-vis';
import { stak, methods } from './test.js';
import Spinner from "react-bootstrap/Spinner";
import '../node_modules/react-vis/dist/style.css';
import Popup from "reactjs-popup";
import {map} from "react-bootstrap/ElementChildren";
require('codemirror/mode/python/python');
require('codemirror/mode/xml/xml');
require('codemirror/mode/javascript/javascript');
const services = new Services();
const history = createBrowserHistory();


let props = () => {
    return (
            <Menu>
                <div style={{overflow: "hidden"}}/>
                <a id={"header"} style={{marginBottom: "10%",  color: "burlywood"}} href="/">
                    <h1 >Sebastian's Portfolio</h1>
                </a>
                <a className="menu-item" href="/projects" style={{  color: "burlywood"}}>
                    Projects
                </a>
                <div style={{fontSize: "12px" }}>
                    {projects.map((project, index) => (
                        <a href={project.link} style={{color: "burlywood", display: "flex"}}>
                            &bull; {project.name}
                        </a>
                    ))}
                </div>
                <a className="menu-item" href={"https://storage.googleapis.com/si-portfolio-files/cv_2023_final.pdf"} style={{  color: "burlywood"}}>
                    CV
                </a>
                <a className="menu-item" href="/skills" style={{  color: "burlywood"}}>
                    Skills
                </a>
                <a className="menu-item" href="/contact" style={{  color: "burlywood"}}>
                    Contact
                </a>
                <div style={{marginTop: "10%"}}>
                    <a className={"git"} href={"https://github.com/Zantiki"}>
                        <i style={{fontSize: "40px"}} className="fab fa-github"/>
                    </a>
                    <a href={"https://www.linkedin.com/in/sebastianakin"}>
                        <i style={{fontSize: "40px"}} className="fab fa-linkedin-in"></i>
                    </a>
                    <a href={"mailto: sebastianaikin@gmail.com"}>
                        <i style={{fontSize: "40px"}}  className="fas fa-envelope"></i>
                    </a>
                    <a href={"tel:+4747266380"}>
                        <i style={{fontSize: "40px"}} className="fas fa-phone-square"></i>
                    </a>
                </div>
                <div className={"row"} style={{ marginTop: "10px",  color: "burlywood"}}>
                    <a style={{fontSize: "10px", fontStyle: "italic"}}>Built with React and Google Cloud by Sebastian Ikin</a>
                </div>
            </Menu>
    );
};

class Intro extends Component{
    render(){
        return(
            <div id="App">
                {props()}
                <div id="page-wrap">
                    <div style={{marginTop: "15%"}}>
                    <h3 style={{display: "flex", justifyContent: 'center', alignItems: 'center'}}>About me</h3>
                    <p3 style={{display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                        Software Engineer based in Trondheim, currently working within testing-automation in the semi-conductor space.
                    </p3>
                    <p3 style={{display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: "10px"}}>
                        I have experience building a multitude of systems and tools using languages Java, Python, C++, SQL, JavaScript.
                    </p3>
                    <p3 style={{display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                        I have worked with infrastructure like Google Cloud and IBM LSF,
                        and also have some practical experience within Software Security.
                    </p3>
                    <p3 style={{display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                        In terms of frameworks I am experienced with Pyspark, Pytorch, Sequelize, React and Express, to mention a few.
                    </p3>
                    </div>
                </div>
            </div>
        )
    }
}

class Skills extends Component{

    tipStyle = {
        display: 'flex',
        color: '#fff',
        alignItems: 'center',
        justifyContent: "center",
        padding: '5px',
        minHeight: "3em",
        maxHeight: "3em",
    };

    boxStyle = {height: '10px', width: '10px'};

    constructor(props){
        super(props);
    }

    handleSelect(selectedIndex, e){
        this.setState({index: selectedIndex});
    };

    render(){
        return(
            <div id="App">
                {props()}
                <div id="page-wrap" style={{overflow: "hidden"}}>
                    <div>
                        <h3 style={{textAlign: "center", marginTop: "20px"}}>Under Construction</h3>
                    </div>
                </div>
            </div>
            )
    }
}

class CV2 extends Component{

    render(){
        return(
            <div id="App">
                {props()}
                <div id="page-wrap" style={{height: "100%"}}>
                    <div style={{justifyContent: "center", alignItems: "center", display: "flex", marginTop: "20px"}}>
                        <div className={"embed-responsive"} style={{paddingBottom:"90vh", marginLeft: "5%", marginRight: "5%"}}>
                                <iframe src="https://storage.googleapis.com/si-portfolio-files/cv_2023_final.pdf" style={{overflowY: "hidden"}}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class Contact extends Component{
    state = {
        toggleForm: false,
        ccString: "",
        mailContents: "",
        buttonIcon: "▼",
        loading: false
    };

    handleCC(event){
        this.setState({ccString: event.target.value});
    }

    handleMailContent(event){
        this.setState({mailContents: event.target.value});
    }

    constructor(){
        super(props);
        this.handleMailContent = this.handleMailContent.bind(this);
        this.handleCC = this.handleCC.bind(this);
    }

    render(){
        return(
            <div id="App">
                {props()}
                <div id="page-wrap">
                    <div style={{marginTop: "20px"}}>
                        <h1>Contact Info</h1>
                        <div style={{display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                            <table>
                                <tbody>
                                <tr>
                                    <td>
                                        Private Email:
                                    </td>
                                    <td>
                                        <a className={"contact-link"} href="mailto: sebastianaikin@gmail.com">sebastianaikin@gmail.com</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Phone:
                                    </td>
                                    <td>
                                        <a className={"contact-link"} href="tel:+4747266380">+4747266380</a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style={{marginLeft: "10px", marginTop: "30px"}}>
                            <button style={{textColor: "burlywood"}} className={"btn btn-info "} onClick={e => this.handleForm(e)}>
                                Toggle Mail-form {this.state.buttonIcon}
                            </button>
                            {this.getMailForm()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    handleForm(){
        console.log("handleForm called");
        if(this.state.toggleForm){
            this.setState({toggleForm: false});
            this.setState({buttonIcon: "▼"});
        }else{
            this.setState({toggleForm: true});
            this.setState({buttonIcon: "▲"});
        }
    }

    getMailForm(){
        if(this.state.toggleForm){
            return(
                <div style={{marginTop: "20px"}}>
                    <h3>Get in touch!</h3>
                    <form style={{textAlign: "left"}}>
                        CC:
                        <div className="form-group">
                            <input type="email" className="form-control" onChange={this.handleCC} id="exampleFormControlInput1" placeholder="name@example.com"/>
                        </div>
                        <div className="form-group">
                            <textarea onChange={this.handleMailContent} className="form-control" id="exampleFormControlTextarea1" rows="5"/>
                        </div>
                    </form>
                    <div style={{textAlign: "right"}}>
                        <button style={{}} disabled={this.state.loading} className={"btn btn-info btn-sm"} onClick={this.sendMail.bind(this)}>
                            <i className="fas fa-paper-plane"/> Send mail &nbsp;{this.state.loading?
                            <Spinner
                                className="mr-2"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"/> : <div/>}
                        </button>
                    </div>
                </div>
            )
        }else{
            return null
        }
    }

    sendMail(){
        console.log(this.state);
        console.log("button pressed");
        this.setState({loading: true});
        services.sendMail(this.state.ccString, this.state.mailContents)
            .then(res => {
                console.log(res);
                this.setState({loading: false});
                alert(res);
            });
    }
}

class CodeRunner extends Component{

    needsAwait = false;
    waiting = false;

    state = {
        code: "print('hello world')",
        output: "",
        loading: false,
        vmState: "LOADING...",
        show: false,
        setShow: false
    };

    options ={
        mode: 'python',
        theme: 'material',
        lineNumbers: true
    };

    handleClose(){
        this.setState({show: false});
    }
    handleShow(){
        this.setState({show: true});
    }

    handleOutput(out){
        let prev = this.state.output;
        this.setState({output: prev+out });
        this.scrollToBottom();
    }

    handleVMState(){
        services.getVMState().then(vm => {
            this.setState({vmState: vm});
            this.handleButtonLock();
        });
    }

    constructor(props){
        super(props);
        this.handleButtonLock();
        services.getVMState()
            .then(state => {
                if(state !== "RUNNING"){
                    this.needsAwait = true;
                }
            });
        setInterval(this.handleVMState.bind(this), 1000)
    }

    scrollToBottom() {
        this.el.scrollIntoView({ behavior: 'smooth' });
    }

    render() {
        return(

            <div id="App">
                {props()}
                <div id="page-wrap">
                    <div id="message" style={{marginLeft: "15px", textAlign: "left", marginTop: "20px"}} >
                        <h3 style={{textAlign: "center"}}>Write some code</h3>
                        <p style={{fontSize: "15px", fontStyle: "italic", textAlign: "center"}}>Remote machine status:
                            <a style={{color: this.stateColor()}}>{this.state.vmState}</a></p>
                        <div style={{alignItems: "center", textAlign: "center"}}>
                            <button className={"btn btn-success"} onClick={event => {this.handleShow()}}>
                                How does it work?
                            </button>
                        </div>

                        {(this.state.vmState === "TERMINATED")?
                            <div>
                                <p style={{fontSize: "10px", fontStyle: "italic",  marginLeft: "5px"}}>Tip: Pressing run program will restart VM</p>
                            </div>: <div/>
                        }
                        <>
                            <Modal show={this.state.show} onHide={event => {this.handleClose()}}>
                                <div style={{backgroundColor: "darkslategrey"}}>
                                <Modal.Header closeButton>
                                    <Modal.Title>How it works:</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p style={{fontStyle: "italic"}}>If the virtual machine encounters an error while running, it will attempt to shut down.
                                        You can restart the VM by pressing run code if it has been terminated. The VM-machine restart usually takes a minute.
                                        Have a read while you wait :)
                                    </p>
                                    <p>This project was built as an attempt to circumvent some of the limitations in
                                    Google Cloud's free tier. It works by running an https-server that establishes a tcp-connection to a
                                    Virtual Machine on Cloud Engine, on this Virtual machine we have another http-server with a websocket. We use
                                    the tcp connection with the websocket protocol to connect to the VM-server, and from there we can run a docker
                                    container. This would normally not be possible on the first server (uses the free variant of Cloud Functions, that can't access
                                        OS-functions), but we
                                        get around the issue by using it as a proxy.</p>
                                    <p>
                                        Sometimes the server on the virtual machine fails (probably due to some kind of
                                        mysterious networking issue with the free tier), when this happens we restart entire virtual machine. On restart
                                        we listen in on the VM-state and initialize the server when an external IP for the VM is resolved. This is done
                                        v.i.a a startup-script in the VMs metadata. The reset of the VM itself is controlled by the https
                                        cloud functions "proxy".
                                    </p>
                                    <p>
                                        A complicated solution, but has potential. You can check out the code here:<a className={"contact-link2"} href={"https://github.com/Zantiki/GoogleCloud-Firebase-Utils"}>GoogleCloud-Firebase-Utils</a>
                                    </p>
                                </Modal.Body>
                                <Modal.Footer>
                                </Modal.Footer>
                                </div>
                            </Modal>
                        </>
                        <h4 style={{marginTop: "10px", marginLeft: "5px"}}>Input</h4>
                        <CodeMirror
                            value={this.state.code}
                            options={this.options}
                            onBeforeChange={(editor, data, value) => {
                                this.setState({code: value});
                            }}
                            onChange={(editor, data, value) => {
                            }}
                        />
                        <button id={"submit"} disabled={this.state.loading} className={"btn btn-success btn-lg btn-block"} onClick={event => { this.submit() }}>Run Program &nbsp;{this.state.loading?
                            <Spinner
                                className="mr-2"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"/> : <div/>}
                        </button>
                        <h4 style={{marginTop: "30px", marginLeft: "5px"}}>Output</h4>
                        <div style={{ height: "150px", overflow: "auto", backgroundColor: "black" }}>
                            {this.state.output.split("\n").map(line => (
                                    <p style={{marginLeft: "5px", color: "green"}}>
                                        {"coderunner> "+line}
                                    </p>
                                )
                            )}
                            <div ref={el => { this.el = el; }}></div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    stateColor(){
        switch(this.state.vmState){
            case "STOPPING":
                return "darksalmon";
            case "TERMINATED":
                return "red";
            case "RUNNING":
                return "dodgerblue";
            case "STAGING":
                return "dimgrey";
            case "PROVISIONING":
                return "grey";
            default:
                return "burlywood";
        }
    }

    submit(){
        console.log(this);
        let code = this.state.code;
        console.log(code);
        this.setState({loading: true});
        services.postCode(code)
            .then(result => {
                console.log(result);
                this.handleOutput(result);
                if(result !== "VM is off, restarting\n"){
                    this.setState({loading: false})
                }
            })
            .catch(err => console.log(err) );
    }

    handleButtonLock(){
        console.log(this.state.vmState);
        console.log(this.waiting);

        if((this.state.vmState === "STAGING") || (this.state.vmState === "PROVISIONING") || (this.state.vmState === "STOPPING")  ){
            this.setState({loading: true});
            return;
        }else if((this.state.vmState === "RUNNING") && this.needsAwait){
            this.needsAwait = false;
            this.setState({output: this.state.output+"Please await start of VM-Server\n"});
            this.handleShow();
            this.waiting = true;
            setTimeout(function(){
                this.waiting = false;
                console.log("timeout called");
                this.setState({loading: false, output: this.state.output+"VM server has restarted\n"});
            }.bind(this), 40000);
            return;
        }else if(this.state.vmState === "TERMINATED"){
                this.needsAwait = true;
                if(this.state.output.indexOf("VM is off, restarting\n") !== this.state.output.length - 22 ){
                    this.setState({loading: false});
                }
                return;
        }else if ((this.state.vmState === "RUNNING") && this.waiting){
            this.setState({loading: true});
            return;
        }
    }

}

class Projects extends Component{


    state = {
        index: 0
    };

    constructor(props){
        super(props);

        window.addEventListener('popstate', function (event) {
            // Log the state data to the console
            console.log(event);
            console.log(event.currentTarget.location.href.split("#")[1]*1);
            window.location.reload();
           // this.handleSelect(event.currentTarget.location.href.split("#")[1]*1);
        });

        if(props.location.hash.split("#")[1]) {
            this.state.index = props.location.hash.split("#")[1]*1;
            console.log(this.state.index);
           // this.index = useState(goto)
        }else{
            console.log("no selector");
        }
    }


    handleSelect(selectedIndex, e){
        this.setState({index: selectedIndex});
    };

    render(){
        return(
            <div id="App">
                {props()}
                <div id="page-wrap">
                        <Carousel onSelect = {this.handleSelect.bind(this)} activeIndex={this.state.index} id={"carousel"}>
                            {projects.map((project, index) => (
                                <CarouselItem style={{marginLeft: "10px"}}>
                                    <div style={{display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: "15%"}}>
                                        <img class="img-fluid" src={project.img} style={{maxHeight: "300px", maxWidth: "300px"}}/>
                                    </div>
                                    <div className="card-body" style={{marginBottom: "20px"}}>
                                        <h3 className="card-title"
                                            style={{display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                                            {project.name}
                                        </h3>
                                        <p className="card-text"
                                           style={{display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                                            {project.description}
                                        </p>
                                        <DropdownButton id="dropdown-basic-button"
                                                        variant={"secondary"} title="Options" block>
                                            {project.options.map(option =>(
                                                <DropdownItem href={option.link}>
                                                    {option.description}
                                                </DropdownItem>
                                            ))}
                                        </DropdownButton>
                                    </div>
                                </CarouselItem>
                                )
                            )}
                        </Carousel>
                </div>
            </div>
        )
    }

}

class Alculator extends Component{

    state={
        BAC: 1,
        hoursDrinking: 1,
        strengthOfDrink: 4,
        sizeOfDrink: 5,
        weight: 80,
        timeValue: ["00:00", "05:00"],
        BACdata: [],
        drinkData: []
    }
    constructor(props) {

        super(props);
    }

    onTimeChange(value){
        console.log(value);
        if (!(value[0] === null || value[1] === null)){
            this.setState({timeValue: value})
        }
    }

    getHours(){
        let start = this.state.timeValue[0];
        let end = this.state.timeValue[1];

        var hourMinute = start.split(":");
        let startHour = hourMinute[0] * 1 + hourMinute[1] / 60;
        hourMinute = end.split(":");
        let stopHour = hourMinute[0] * 1 + hourMinute[1] / 60;
        let totalHours = stopHour - startHour;
        if (totalHours < 0) totalHours = 24 + totalHours;
        return totalHours;
    };

    asTime(hour){
        let start = this.state.timeValue[0];

        let timeList = start.split(":");
        let startHour = timeList[0] * 1 + timeList[1] / 60;

        let hourMinute = startHour + hour;
        let hourAdjusted = Math.floor(hourMinute);
        let minute = Math.round((hourMinute - hourAdjusted) * 60);
        if (minute === 60){
            hourAdjusted++;
            minute=0;
        }
        return hourAdjusted+":"+minute
    };


    computeBAC(){
        let maxDrinkInterval = 0.25;
        let stepSize = 1 / 60; // one min
        let time = this.getHours();
        let drinks = [{x: 0, y: this.state.BAC}];
        let timeCount = 0;
        let minDrinkInterval = 15;

        let bacIncrease = ((((this.state.sizeOfDrink/10) *  3.38 ) * this.state.strengthOfDrink * 0.075) / this.state.weight) * 100;

        let points = [{x:this.asTime(0), y:0}];
        for(let i = stepSize; i < time; i += stepSize ){
            let x = i;
            let y = 0;
            for(let drink in drinks){
                let y_drink = (((((this.state.sizeOfDrink/10) *  3.38 ) * this.state.strengthOfDrink * 0.075) / this.state.weight) - ( (i-drinks[drink].x) * (0.015/drinks.length)) ) * 100;
                if (y < 0){
                    y = 0;
                }
                if (y_drink < 0){
                    y_drink = 0
                }
                y += y_drink
            }

            if(y <  this.state.BAC && timeCount >= 15){
                if ((y + bacIncrease) < (this.state.BAC * 1.05) ){
                    timeCount = 0;
                    drinks.push({x: i, y: this.state.BAC})
                }

            }

            points.push({x: this.asTime(x), y: y});
            timeCount++;
        }
        let cleanedPoints = [];
        for(let i in drinks){
            let index = Math.round(60 * (drinks[i].x + stepSize) );
            let point = points[index];
            point.x = this.asTime(drinks[i].x);
            cleanedPoints.push(point)
        }
        let time_indexed_drinks = [];

        for(let i in drinks){
            time_indexed_drinks.push({x: this.asTime(drinks[i].x), y: drinks[i].y})
        }

        this.setState({drinkData: time_indexed_drinks, BACdata: cleanedPoints });
        console.log(this.state)
    }

    render() {
        return (

            <div id="App">
                {props()}
                <div id="page-wrap">
                    <div id={"input_fields"} style={{marginTop: "30px"}}>
                        <h2>Alculator</h2>
                        <div className="slidecontainer">
                            <label>Ideal BAC:  {this.state.BAC}</label>
                            <input onChange={ (e) => this.setState({BAC: e.target.value/100})} style={{marginLeft: "10px"}} type="range" min="61" max="300" className="slider" id="myRange1"/>
                        </div>
                        <div className="slidecontainer">
                            <label>Size of drink:  {this.state.sizeOfDrink} dL</label>
                            <input onChange={ (e) => this.setState({sizeOfDrink: e.target.value/100})} style={{marginLeft: "10px"}} type="range" min="33" max="1000" className="slider" id="myRange2"/>
                        </div>
                        <div className="slidecontainer">
                            <label>Strength of drink:  {this.state.strengthOfDrink}%</label>
                            <input onChange={ (e) => this.setState({strengthOfDrink: e.target.value/1})} style={{marginLeft: "10px"}} type="range" min="4" max="60" className="slider" id="myRange3"/>
                        </div>
                        <div className={"inputContainer"}>
                            <label htmlFor="quantity">Your weight in KG:</label>
                            <input type="number" id="quantity" onChange={ e => this.setState({weight: e.target.value/1})} name="quantity" min={30} max={200} style={{marginLeft: "10px"}} />
                        </div>
                        <div style={{textColor: "burlywood"}} >
                            <div style={{textAlign: "center"}}>
                                <h style={{marginTop: "10px"}}>Period drinking</h>
                            </div>
                            <TimeRangePicker
                                onChange={this.onTimeChange.bind(this)}
                                value={this.state.timeValue}
                                clearIcon={null}
                                disableClock={true}
                            />
                        </div>

                        <div style={{textAlign: "center", marinTop: "20px"}}>
                            <button style={{textColor: "burlywood", marinTop: "20px"}} className={"btn btn-success "} onClick={this.computeBAC.bind(this)}>
                                Generate Graph
                            </button>
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: "20px"}}>
                        <XYPlot width={1000} height={300} xType={"ordinal"}>
                            <VerticalGridLines />
                            <HorizontalGridLines />
                            <XAxis />
                            <YAxis bottom={0}/>
                            <LineMarkSeries
                                className="linemark-series-example-2"
                                curve={'curveMonotoneX'}
                                data={this.state.BACdata}
                            />
                            <LineMarkSeries
                                className="linemark-series-example-2"
                                style={{
                                    strokeWidth: '2px'
                                }}
                                lineStyle={{stroke: 'red'}}
                                markStyle={{stroke: 'red'}}
                                curve={'curveMonotoneX'}
                                data={this.state.drinkData}
                            />
                        </XYPlot>
                    </div>
                    <div>
                        <h1>Number of drinks:<a style={{color: "red"}}>{this.state.drinkData.length}</a>, Hours drinking:<a style={{color: "dodgerblue"}}>{this.getHours()}</a></h1>
                    </div>
                </div>
            </div>
        )
    }

}


const root = document.getElementById('root');
if (root) ReactDOM.render(
        <BrowserRouter history={history}>
            <Route exact path="/" component={Intro}/>
            <Route exact path="/projects" component={Projects}/>
            <Route exact path="/cv" component={CV2}/>
            <Route exact path="/coderunner" component={CodeRunner}/>
            <Route exact path="/contact" component={Contact}/>
            <Route exact path="/skills" component={Skills}/>
        </BrowserRouter>,
    root
    );
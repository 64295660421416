
import axios from 'axios';

let serverUrl = '';
if (window.location.href.includes('localhost:5000')) {
    serverUrl = 'http://localhost:5001/portfolio-91287/us-central1/webApi/api/v1/';
} else if (window.location.href.includes('localhost:3000')) {
    serverUrl = 'http://localhost:8080/';
} else {
    serverUrl = 'https://us-central1-portfolio-91287.cloudfunctions.net/webApi/api/v1/';
}


class Cv {
    experience = [];
    education = [];
    competencies = [];
    interests = [];

    constructor(experience, education){
        if(experience && education){
            this.education = education;
            this.experience = experience;
        }else{
            this.setPlaceholder()
        }
    }

    setPlaceholder(){
        this.experience=[
                {
                    company: "NTNU, Trondheim",
                    period: "Nov 2019 - April 2020",
                    title: "Research Assistant",
                    description: "Contract based web and game development on existing website for Bli Med Oss project part of Dept. of Civil" +
                        " and Environmental Engineering. "
                },
            {
                company: "SIT, Trondheim",
                period: "June 2019 - Aug 2019",
                title: "Washing Assistant",
                description: "Washing and inspections of empty dorms."
            },
            {
                company: "FUS Kråka, Moss",
                period: "March 2018 - July 2018",
                title: "Kinder Garden Assistant",
                description: "Help with diaper-changes, play, walks and cooking in the toddlers department."
            },
            {
                company: "Forsvaret, Troms",
                period: "Jan 2017 - Dec 2017",
                title: "Military Service",
                description: "Conscripted Guard with the Norwegian Intelligence Battalion."
            },
            {
                company: "Millen Wholesales, Tasmania",
                period: "Sept 2016 - Dec 2016",
                title: "Storeman",
                description: "Storage work at a hair product and beauty supplier in Australia."
            }
    ];
        this.education=[
                {
                    school: "NTNU",
                    department: "IDI",
                    degree: "Bachelor of Engineering, Computer Science",
                    period: "2018-2021"
                }
                ];
        this.competencies = [
                "Norwegian drivers licence class B.",
               "Fluent in Norwegian and speak some Spanish.",
                "Stage 2 Military First Aid."

        ];
        this.interests = "Music, movies, technology, science and cooking."
    }

}

class Project {

    name;
    description;
    img;
    options = [];
    link;

    constructor(name, description, img, options, link){
        this.name = name;
        this.description = description;
        this.img = img;
        this.link = link;
        if(options){
            this.options = options;
        }else{
            this.setDefaultOptions();
        }

    }

    setDefaultOptions(){
       let git = {
            description: "View source",
            link: "https://github.com/Zantiki/"+this.name
        };

        let hosted = {
            description: "Try the project",
            link: "sebastianikin.com/"+this.name
        };

        let executable ={
            description: "Download .exe",
            link: "hosted.com"
        };

        this.options.push(git);
        this.options.push(hosted);
        this.options.push(executable);
    }
}

export class Services{

    sendMail(ccString, contents){
        let cc = [];
        if(ccString.includes(", ")){
            cc = ccString.split(", ");
        }else{
            cc.push(ccString);
        }

        return axios.post(serverUrl+"mail", {cc: cc, contents: contents})
            .then(res => res.data.data);
    }

    postCode(data) {
        console.log(data);
        console.log(serverUrl);
        //this.ws.send(JSON.stringify({code: data}))
        //this.test();
        return axios.post(serverUrl+"code", {code: data})
            .then(res => res.data.data);
    }

    getVMState(){
        return axios.get(serverUrl+"vmstate")
            .then(res => res.data.data);
    }

    fetchCV(){

        return new Cv();

       /* return axios.get(serverUrl+"cv")
            .then(res => {
                console.log(res);
            });*/
    }
}

export let projects = [
    new Project("Coderunner",
        "Compile and run python code in your browser!",
        "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/1024px-Python-logo-notext.svg.png",
        [
            {description: "View source", link: "https://github.com/Zantiki/GoogleCloud-Firebase-Utils"},
            {description: "Try it!", link: "/coderunner"}
        ], "/coderunner"),
    new Project("Bli Med Oss 3D Games",
        "Two 3D-games developed in tandem with Bli Med Oss project in the Dept. of Civil and Environmental Engineering at NTNU ",
        "https://bmo-3d.firebaseapp.com/3D/GoogleEarthSevenBlocks.png",
        [
            {description: "Try the feedback game!", link: "https://www.blimedoss.com/feedback-3d"},
            {description: "Try the benefits game!", link: "https://bmo-3d.firebaseapp.com/3D/table.html?eng"}
        ], "/projects/#2"),
];

export let cv = new Cv();




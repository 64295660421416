

import Rainbow from 'rainbowvis.js' ;
let myRainbow = new Rainbow();

export let stak = {
  "title": "Stack",
  "color": myRainbow.colorAt(60) ,
  "children": [
    {
      "title": "JavaScript", "size": 600, "color": myRainbow.colorAt(24),
      "children": [
        {"title": "Node", "color": myRainbow.colorAt(2), "size": 2000*0.20 },
        {"title": "React", "color": myRainbow.colorAt(6), "size": 2000*0.20},
        {"title": "Three", "color": myRainbow.colorAt(10), "size": 2000*0.15},
        {"title": "Express", "color": myRainbow.colorAt(14), "size": 2000*0.20},
        {"title": "Sequelize", "color": myRainbow.colorAt(20), "size": 2000*0.15}
      ]
    },
    {
      "title": "Java", "size": 1500,
      "children": [
        {"title": "JDBC", "color": "#12939A", "size": 2000*0.15},
        {"title": "JavaFX", "color": "#12939A", "size": 2000*0.15 },
      ]
    },
    {
      "title": "HTML", "size": 1500,
      "children": [
        {"title": "jQuery", "color": "#12939A", "size": 1500*0.20},
        {"title": "CSS", "color": "#12939A", "size": 1500*0.20},
      ]
    },
    {
      "title": "Python", "size": 1000,
      "children": [
        {"title": "tkinter", "color": "#12939A", "size": 2000*0.05},
        {"title": "Beautiful Soup", "color": "#12939A", "size": 2000*0.07},
        {"title": "matplotlib", "color": "#12939A", "size": 2000*0.05},
        {"title": "turtle", "color": "#12939A", "size": 2000*0.15}
      ]
    },
    {
      "title": "C++", "size": 1000,
      "children": [
        {"title": "sys/socket", "color": "#12939A", "size": 1000*0.10}
      ]
    },
    {
      "title": "SQL", "size": 1500,
      "children": [
        {"title": "MariaDB", "color": "#12939A", "size": 1500*0.10},
        {"title": "Google Cloud SQL", "color": "#12939A", "size": 1500*0.10},
      ]
    }
  ]
};

export let methods = {
  "title": "Methods",
  "color": myRainbow.colorAt(80),
  "children": [
    {
      "title": "REST",
      "children": [

      ]
    },
    {
      "title": "OOP",
      "children": [
      ]
    },
    {
      "title": "Lean Development", "size": 2000,
      "children": [
        {"title": "SCRUM", "color": "#12939A", "size": 4000},
        {"title": "TDD", "color": "#12939A", "size": 4000},

      ]
    },
    {
      "title": "VC",
      "children": [
        {"title": "GitLab", "color": "#12939A", "size": 3000},
        {"title": "GitHub", "color": "#12939A", "size": 3000},
        {"title": "CI", "color": "#12939A", "size": 3000}
      ]
    },
    {
      "title": "Google Cloud",
      "children": [
        {"title": "Firebase", "color": "#12939A", "size": 2000},
        {"title": "Compute Engine", "color": "#12939A", "size": 500},
        {"title": "Cloud SQL", "color": "#12939A", "size": 500},
        {"title": "Cloud Functions", "color": "#12939A", "size": 1000},
        {"title": "Cloud Storage", "color": "#12939A", "size": 1000}
      ]
    }
  ]
};

function setColor(three, upper, lower) {
  /*let upper = 100;
  let lower = 60;*/
  let range = three.children.length;
  let dist = (upper-lower) /range;

  let ranges = [];

  three.children.map(child => {
    child.color = myRainbow.colorAt(lower);
    lower += dist;
    if(child.children.length != 0){
      let subdist = (dist) /child.children.length;
      let i = 1;
      child.children.map(subchild => {
        console.log((i*subdist) + lower);
        subchild.color = myRainbow.colorAt((i*subdist) + lower);
        console.log(subchild.color);
        i++;
      });
    }
  })

}

setColor(stak, 60, 100);
setColor(methods, 80, 40);
